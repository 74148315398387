<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				market {{perf}}
				<DataTable :value="markets" class="p-datatable-sm" 
					v-model:filters="filters1" responsiveLayout="scroll" showGridlines stripedRows
					:globalFilterFields="['market_cd', 'market_name', 'market_desc']"
          selectionMode="single">
					<template #header>
						<span class="p-input-icon-left">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="キーワード" />
						</span>
					</template>
					<Column field="id" header="No" :sortable="true"></Column>
					<Column field="market_cd" header="コード" :sortable="true"></Column>
					<Column field="market_name" header="市場" :sortable="true"></Column>
					<Column field="market_name_short" header="市場short" :sortable="true"></Column>
					<Column field="market_name_jpx" header="市場名jpx" :sortable="true"></Column>
					<Column field="market_desc" header="説明" :sortable="true"></Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import MarketService from '@/service/MarketService';

export default {
	data() {
		return {
			markets: null,
			filters1: {},
			perf: null,
		}
	},
	marketService: null,
	created() {
		this.marketService = new MarketService();
		this.initFilters1();
	},
	mounted() {
		this.marketService.getAll()
			.then(d => {
				this.markets = d.data;
				this.perf = d.perf;
			}
		);
	},
	methods: {
		initFilters1() {
			this.filters1 = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
			}
		}
	}
}
</script>

<style scoped>

</style>
